import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with Loxone",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Loxone/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Loxone"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT Server with Loxone' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Loxone/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Loxone/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-server-with-loxone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-loxone",
        "aria-label": "instar mqtt server with loxone permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with Loxone`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#loxone-configuration"
        }}>{`Loxone Configuration`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "loxone-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#loxone-configuration",
        "aria-label": "loxone configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loxone Configuration`}</h2>
    <p>{`see `}<a parentName="p" {...{
        "href": "https://www.loxwiki.eu/pages/viewpage.action?pageId=82149712"
      }}>{`Loxberry MQTT Gateway`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      